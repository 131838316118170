* {
  margin: 0;
  padding: 0;
  outline: none !important;
  -webkit-appearance: none;
  box-sizing: border-box;
  white-space: pre-line;
  text-decoration: none;
}

a {
  color: var(--text-color);
}
:root {
  --text-color: #172473;
  /* --text-color: #2e4562; */
  --main-bg: linear-gradient(90deg, #1c4d9b 0%, #0dbefe 100%);
  --mainish-bg: hsla(0, 93%, 65%, 0.295);
  --mainish-color: #c62e2e;
  --main-color: #c62e2e;

  --bluegray-bg: rgba(48, 127, 159, 0.09);
  --blue-bg: linear-gradient(90deg, #1c4d9b 0%, #2596be 100%);
  --red-bg: linear-gradient(
    45deg,
    rgba(255, 99, 99, 1) 0%,
    rgba(255, 162, 153, 1) 100%
  );
  --green-bg: linear-gradient(45deg, rgb(0, 187, 47) 0%, rgb(0, 172, 80) 100%);
  --green-color: rgb(0, 187, 47);
  --greenish-bg: rgba(0, 206, 51, 0.1);

  --purple-bg: linear-gradient(
    45deg,
    rgba(180, 0, 250, 1) 0%,
    rgba(222, 0, 255, 1) 100%
  );
  --orange-bg: linear-gradient(
    45deg,
    rgb(255, 124, 2) 0%,
    rgb(255, 201, 51) 100%
  );
  --lightgray: RGBA(0, 0, 0, 0.1);
  --lightergray: RGBA(0, 0, 0, 0.05);
  --gray: RGBA(0, 0, 0, 0.2);
  --darkgray: RGBA(0, 0, 0, 0.4);
  --darkergray: RGBA(0, 0, 0, 0.6);

  /* --main-bg: linear-gradient(
    90deg,
    rgb(248, 96, 88) 0%,
    rgb(255, 142, 142) 100%
  );
  --mainish-bg: rgba(255, 0, 0, 0.2);
  --mainish-color: rgba(255, 38, 0, 0.671); */
}

body .fc-license-message {
  display: none !important;
}

input,
select,
textarea,
.input,
.htmlArea {
  background: RGBA(0, 0, 0, 0.08);
  padding: 15px 15px;
  width: 100%;
  margin: 0px;
  border-radius: 5px;
  border: none;
  display: inline-block;
}

.input:active,
.input:focus,
.searchBar:active,
.searchBar:focus,
.searchBar:has(input:focus),
textarea:active,
textarea:focus,
select:active,
select:focus,
input:active,
input:focus {
  background: rgba(0, 119, 183, 0.18);
}

.expansionTile.active input,
.expansionTile.active select,
.expansionTile.active textarea,
.expansionTile.active .input,
.expansionTile.active .htmlArea,
.expansionTile.active .css-t24hwh-control {
  background-color: rgba(0, 0, 0, 0.15);
  color: white !important;
}
.expansionTile.active .css-1dimb5e-singleValue {
  color: white !important;
}

body,
html {
  color: var(--text-color);
  padding: 0px;
  margin: 0px;
  height: 100vh;
  overflow: hidden;
  font-size: 13px;
}

#root {
  display: grid;
  grid-template-columns: 160px calc(100vw - 160px);
  height: 100vh;
  width: 100vw;
}

/* 
///////////////////////////////////
//////////////  SCROLLBAR  //////////////
///////////////////////////////////
*/
::placeholder {
  color: RGBA(0, 0, 0, 0.45);
}
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: RGBA(0, 0, 0, 0.07);
}
::-webkit-scrollbar-thumb {
  background: RGBA(0, 0, 0, 0.2);
  border-radius: 30px;
  overflow: hidden;
}
::-webkit-scrollbar-thumb:hover {
  background: RGBA(0, 0, 0, 0.3);
}

.skeletonTile {
  background: RGBA(0, 0, 0, 0.17);
  border-radius: 5px;
  padding: 30px;
  align-items: center;
  width: 100%;
  animation: skeleton 1.6s infinite;
}

@keyframes skeleton {
  0% {
    opacity: 0.3;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.noCalendarIcon input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.noCalendarIcon input {
  -moz-appearance: textfield;
}

.highlight {
  color: rgb(255, 99, 99);
}

.desktopOnly {
  display: block;
}
.mobileOnly {
  display: none;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

@media screen and (max-width: 800px) {
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
}

/* 
///////////////////////////////////
//////////////  LAYOUTS  //////////////
///////////////////////////////////
*/

.flex {
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
}

.c1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.c2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.c3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.c4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.c5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.c6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.c7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.c8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.c9 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}
.c10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.c1-auto {
  display: grid;
  grid-template-columns: 1fr auto;
}

.cauto-1 {
  display: grid;
  grid-template-columns: auto 1fr;
}

.cauto2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.cauto3 {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.cauto4 {
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.cauto5 {
  display: grid;
  grid-template-columns: repeat(5, auto);
}

.gap5 {
  gap: 5px !important;
}
.gap10 {
  gap: 10px !important;
}
.gap15 {
  gap: 15px !important;
}
.gap20 {
  gap: 20px !important;
}
.gap25 {
  gap: 25px !important;
}
.gap30 {
  gap: 30px !important;
}
.gap35 {
  gap: 35px !important;
}
.gap40 {
  gap: 40px !important;
}

.double {
  grid-column: span 2;
}

.triple {
  grid-column: span 3;
}

@media screen and (min-width: 80px) and (max-width: 801px),
  screen and (orientation: portrait) {
  body *.mc1 {
    grid-template-columns: repeat(1, 1fr);
  }
  body *.mc2 {
    grid-template-columns: repeat(2, 1fr);
  }
  body *.mc3 {
    grid-template-columns: repeat(3, 1fr);
  }
  body *.mc4 {
    grid-template-columns: repeat(4, 1fr);
  }
  body *.mc5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .mdouble {
    grid-column: span 2;
  }

  .msingle {
    grid-column: span 1;
  }

  .mgap5 {
    gap: 5px !important;
  }
  .mgap10 {
    gap: 10px !important;
  }
  .mgap15 {
    gap: 15px !important;
  }
  .mgap20 {
    gap: 20px !important;
  }
  .mgap25 {
    gap: 25px !important;
  }
  .mgap30 {
    gap: 30px !important;
  }
  .mgap35 {
    gap: 35px !important;
  }
  .mgap40 {
    gap: 40px !important;
  }
}

.iconButton {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: grid;
  place-items: center;
  background: RGBA(0, 0, 0, 0.1);
  cursor: pointer;
}

.iconButton.loading {
  opacity: 0.5;
}

.iconButton.main {
  background: var(--main-bg);
  color: white;
}
.iconButton.secondary {
  background: transparent;
}

.iconButton:hover {
  background: RGBA(0, 0, 0, 0.3);
}

/* 
///////////////////////////////////
//////////////  COLORS  //////////////
///////////////////////////////////
*/

.main-color {
  background: var(--main-bg);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-bg {
  background: var(--main-bg);
}

.blue-bg {
  background: var(--blue-bg);
}

.turquoise-bg {
  background: rgb(0, 212, 179);
  background: linear-gradient(
    45deg,
    rgba(0, 212, 179, 1) 0%,
    rgba(1, 231, 215, 1) 100%
  );
}
.brown-bg {
  background: rgb(211, 88, 0);
  background: linear-gradient(
    49deg,
    rgba(211, 88, 0, 1) 0%,
    rgba(255, 151, 40, 1) 100%
  );
}
.orange-bg {
  background: rgb(255, 154, 29);
  background: linear-gradient(
    45deg,
    rgb(255, 124, 2) 0%,
    rgb(255, 201, 51) 100%
  );
}
.green-bg {
  background: rgb(0, 206, 51);
  background: linear-gradient(45deg, rgb(0, 187, 47) 0%, rgb(0, 172, 80) 100%);
}
.green2-bg {
  background: rgb(0, 191, 48);
  background: linear-gradient(45deg, rgb(0, 172, 43) 0%, rgb(0, 206, 96) 100%);
}
.greenish-bg {
  background: rgba(0, 206, 51, 0.1);
}
.red-bg {
  background: rgb(255, 99, 99);
  background: linear-gradient(
    45deg,
    rgba(255, 99, 99, 1) 0%,
    rgba(255, 162, 153, 1) 100%
  );
}
.red-bg2 {
  background: rgb(255, 183, 183);
  background: linear-gradient(
    45deg,
    rgba(255, 183, 183, 1) 0%,
    rgba(255, 201, 196, 1) 100%
  );
}
.purple-bg {
  background: rgb(180, 0, 250);
  background: linear-gradient(
    45deg,
    rgba(180, 0, 250, 1) 0%,
    rgba(222, 0, 255, 1) 100%
  );
}
.purple-bg2 {
  background: rgb(156, 0, 218);
  background: linear-gradient(
    45deg,
    rgb(141, 0, 197) 0%,
    rgb(184, 0, 212) 100%
  );
}
.pink-bg {
  background: rgb(232, 47, 179);
  background: linear-gradient(
    45deg,
    rgba(232, 47, 179, 1) 0%,
    rgba(255, 67, 147, 1) 100%
  );
}
.black-bg {
  background: #6e7490;
}
.dark-bg {
  background: #8a92b6;
}
.gray-bg {
  /* background: RGBA(0, 0, 0, 0.07); */
  background: var(--bluegray-bg);
}
.gray-bg2 {
  background: RGBA(0, 0, 0, 0.07);
}
.white-bg {
  background: #fff;
}
.dark-gray-bg {
  background: RGBA(0, 0, 0, 0.12);
}
.darker-gray-bg {
  background: RGBA(0, 0, 0, 0.2);
}
.blue-color {
  color: rgb(0, 140, 255);
}
.red-color {
  color: rgb(255, 99, 99);
}
.orange-color {
  color: rgb(255, 154, 29);
}

.purple-color {
  background: rgb(180, 0, 250);
  background: linear-gradient(
    45deg,
    rgba(180, 0, 250, 1) 0%,
    rgba(222, 0, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-color {
  background: rgb(232, 47, 179);
  background: linear-gradient(
    45deg,
    rgba(232, 47, 179, 1) 0%,
    rgba(255, 67, 147, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gray {
  color: RGBA(0, 0, 0, 0.4);
}
.inheritColor {
  color: inherit;
}
.black {
  color: #2e4562;
}
.dark-gray {
  color: #8a8a8a;
}
.medium-gray {
  color: #a7a7a7;
}
.light-gray {
  background: #f5f5f5;
}
.light-white {
  color: RGBA(255, 255, 255, 0.9);
}
.white {
  color: RGBA(255, 255, 255, 1);
}
.lc {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.redish,
.list-element.redish {
  background: rgba(255, 99, 99, 0.1) !important;
  color: rgb(255, 99, 99);
}

.blueish,
.list-element.blueish {
  background: rgba(0, 164, 255, 0.1);
  color: rgb(0, 164, 255);
}
.greenish,
.list-element.greenish {
  background: rgba(0, 206, 51, 0.1);
  color: rgb(0, 194, 48);
}
.orangeish,
.list-element.orangeish {
  background: rgba(255, 154, 29, 0.1);
  color: rgb(255, 154, 29);
}
.purpleish,
.list-element.purpleish {
  background: rgba(180, 0, 250, 0.1);
  color: rgb(180, 0, 250);
}
.darker-grayish,
.list-element.darker-grayish {
  background: RGBA(0, 0, 0, 0.05);
  color: RGBA(0, 0, 0, 0.4);
}

/* 
///////////////////////////////////
//////////////  UTILS  //////////////
///////////////////////////////////
*/
.inline {
  display: inline-block;
}
.hidden {
  display: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}

.tcenter {
  text-align: center;
}
.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.right {
  text-align: right;
}

.acenter {
  align-items: center;
}
.astart {
  align-items: start;
}
.aend {
  align-items: end;
}

.center {
  display: grid;
  place-items: center;
}
.center {
  display: grid;
  place-items: center;
}

.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
.grab:active {
  cursor: grabbing;
}

.bs {
  box-shadow: 5px 5px 10px RGBA(0, 0, 0, 0.06);
}
.bs2 {
  box-shadow: 8px 8px 20px RGBA(0, 0, 0, 0.06);
}
.bs3 {
  box-shadow: 1px 1px 10px RGBA(0, 0, 0, 0.1);
}

.o-hidden {
  overflow: hidden;
}
.scroll {
  overflow: scroll;
}
.scroll-y {
  overflow-y: scroll;
}
.scroll-x {
  overflow-x: scroll;
}
.circle {
  overflow: hidden;
  border-radius: 100%;
}

.relative {
  position: relative;
}

.br {
  border-radius: 10px;
}
.br2 {
  border-radius: 20px;
}

/* 
///////////////////////////////////
//////////////  PADDINGS  //////////////
///////////////////////////////////
*/

.p5 {
  padding: 5px !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
.p20 {
  padding: 20px !important;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p30 {
  padding: 30px;
}
.p50 {
  padding: 50px;
}

.p0x5 {
  padding: 0px 5px;
}
.p0x10 {
  padding: 0px 10px;
}
.p0x15 {
  padding: 0px 15px;
}
.p0x20 {
  padding: 0px 20px;
}
.p0x25 {
  padding: 0px 25px;
}
.p0x30 {
  padding: 0px 30px;
}

.pleft5 {
  padding-left: 5px;
}
.pleft10 {
  padding-left: 10px;
}
.pleft15 {
  padding-left: 15px;
}
.pleft20 {
  padding-left: 20px;
}

.p5x10 {
  padding: 5px 10px;
}

.p5x0 {
  padding: 5px 0px;
}
.p10x0 {
  padding: 10px 0px;
}
.p15x0 {
  padding: 15px 0px;
}
.p20x0 {
  padding: 20px 0px;
}
.p25x0 {
  padding: 25px 0px;
}
.p30x0 {
  padding: 30px 0px;
}

.p10x15 {
  padding: 10px 15px;
}
.p10x20 {
  padding: 10px 20px;
}

.ptop5 {
  padding-top: 5px;
}
.ptop10 {
  padding-top: 10px;
}
.ptop15 {
  padding-top: 15px;
}
.ptop20 {
  padding-top: 20px;
}
.pbottom5 {
  padding-bottom: 5px;
}
.pbottom10 {
  padding-bottom: 10px;
}
.pbottom15 {
  padding-bottom: 15px;
}
.pbottom20 {
  padding-bottom: 20px;
}

.mauto {
  margin: auto;
}
.mtop5 {
  margin-top: 5px;
}
.mtop10 {
  margin-top: 10px;
}
.mtop15 {
  margin-top: 15px;
}
.mtop20 {
  margin-top: 20px;
}
.mtop25 {
  margin-top: 25px;
}
.mtop35 {
  margin-top: 35px;
}
.mtop50 {
  margin-top: 50px;
}
.mtop100 {
  margin-top: 100px;
}
.mbottom5 {
  margin-bottom: 5px;
}
.mbottom10 {
  margin-bottom: 10px;
}
.mbottom15 {
  margin-bottom: 15px;
}
.mbottom20 {
  margin-bottom: 20px;
}
.mbottom25 {
  margin-bottom: 25px;
}
.mbottom30 {
  margin-bottom: 30px;
}

.mright5 {
  margin-right: 5px;
}
.mright10 {
  margin-right: 10px;
}
.mright15 {
  margin-right: 15px;
}
.mright20 {
  margin-right: 20px;
}
.mright30 {
  margin-right: 30px;
}
.mright40 {
  margin-right: 40px;
}
.mright50 {
  margin-right: 50px;
}
.mright100 {
  margin-right: 100px;
}

.mleft5 {
  margin-left: 5px;
}
.mleft10 {
  margin-left: 10px;
}
.mleft15 {
  margin-left: 15px;
}
.mleft20 {
  margin-left: 20px;
}

.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}

.m0x5 {
  margin: 0px 5px;
}
.m0x10 {
  margin: 0px 10px;
}
.m0x15 {
  margin: 0px 15px;
}
.m0x20 {
  margin: 0px 20px;
}
.m0x25 {
  margin: 0px 25px;
}
.m0x30 {
  margin: 0px 30px;
}
.m5x40 {
  margin: 5px 40px;
}

.m5x0 {
  margin: 5px 0px;
}
.m10x0 {
  margin: 10px 0px;
}
.m15x0 {
  margin: 15px 0px;
}
.m20x0 {
  margin: 20px 0px;
}

.indent {
  margin-left: 10px;
}
.indent20 {
  margin-left: 20px;
}

/* 
///////////////////////////////////
//////////////  Sizes  //////////////
///////////////////////////////////
*/

.f10 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}

/* 
///////////////////////////////////
//////////////  BTNS  //////////////
///////////////////////////////////
*/
.noSelect {
  user-select: none;
}

.btn {
  display: grid;
  place-items: center;
  vertical-align: top;
  border-radius: 4px;
  text-align: center;
  padding: 13px 15px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  background: var(--main-bg);
  color: white;
  border-radius: 10px;
  height: 100%;
  /* color:rgb(0, 164, 255);
  font-weight: bold;
  background: rgb(0, 164, 255,0.2); */
}

.white-btn {
  color: var(--mainish-color);
  font-weight: bold;
}

.disabled {
  opacity: 0.4;
}
.softDisabled {
  opacity: 0.6;
}

.btn.disabled {
  opacity: 0.4;
  cursor: default;
}

.card.loading {
  opacity: 0.5;
}

.simple-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12) !important;
}

.btn:hover {
  filter: saturate(2);
}

.tertiary {
  font-weight: bold;
  color: var(--mainish-color);
  border: none;
  background: RGBA(0, 0, 0, 0.08);
  background: var(--mainish-bg);
}

.quaternary {
  font-weight: bold;
  color: var(--mainish-color);
  border: none;
  background: transparent;
}

.quaternary:hover {
  background: RGBA(0, 0, 0, 0.08);
}

.btn.secondary {
  padding: 8px 13px;
  border-width: 2.5px;
  border-style: solid;
  font-weight: bold;
  border-color: rgb(0, 164, 255, 0.8);
  color: rgb(0, 164, 255, 0.8);
  background: transparent;
}
/* .secondary:not(.secondary:hover) {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.secondary.main-background {
  border-color: var(--mainish-color);
  color: var(--mainish-color);
}
.secondary.blue-bg {
  border-color: rgb(93, 63, 211);
}
.secondary.turquoise-bg {
  border-color: rgb(0, 212, 179);
}
.secondary.brown-bg {
  border-color: rgb(211, 88, 0);
}
.secondary.orange-bg {
  border-color: rgb(255, 154, 29);
}
.secondary.green-bg {
  border-color: rgb(0, 206, 51);
}
.secondary.green2-bg {
  border-color: rgb(0, 191, 48);
}
.secondary.red-bg {
  border-color: rgb(255, 99, 99);
}
.secondary.red-bg2 {
  border-color: rgb(255, 183, 183);
}
.secondary.purple-bg {
  border-color: rgb(180, 0, 250);
}
.secondary.purple-bg2 {
  border-color: rgb(156, 0, 218);
}
.secondary.pink-bg {
  border-color: rgb(232, 47, 179);
}

/*
 ///////////////////////////////////////////////////////////////
 //////////////////	Dialog 	/////////////////////////////////
  ///////////////////////////////////////////////////////////////
 */
.dialog-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: RGBA(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
}

.dialog {
  animation: slide_down 0.15s forwards;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-width: 350px;
  max-height: 90vh;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.dialog.closing {
  animation: slide_up 0.15s forwards;
}

.dialog-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid RGBA(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px RGBA(0, 0, 0, 0.12);
}
.dialog-header .back-button {
  cursor: pointer;
  padding: 2px 15px 0px 20px;
}
.dialog-header .back-button img {
  height: 20px;
  filter: brightness(0);
}
.dialog-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: RGBA(255, 255, 255, 0.1);
  color: #fff;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
}
.dialog-body {
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  background: RGBA(0, 0, 0, 0.05);
}
.dialog.noPadding .dialog-body {
  padding: 0px;
}

.dialog.whiteBody .dialog-body {
  background: #fff;
}

.dialog-footer {
  display: flex;
  gap: 15px;
  justify-content: right;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px RGBA(0, 0, 0, 0.12);
}
.dialog-footer .btn {
  padding: 10px 5px;
}

@keyframes slide_down {
  from {
    top: -20vh;
    opacity: 0.1;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes slide_up {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: -20vh;
    opacity: 0.1;
  }
}

.ai-loading {
  animation: ai_loading 1s forwards;
  animation-delay: 2s;
}

@keyframes ai_loading {
  from {
    display: inline-block;
  }
  to {
    display: none;
  }
}
.ai-fade,
.ai-table td,
.ai-table th {
  animation: ai_fade 1s forwards;
  position: relative;
  opacity: 0;
}

@keyframes ai_fade {
  from {
    top: -5vh;
    opacity: 0.1;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

/*
  ///////////////////////////////////////////////////////////////
  //////////////////	Drawer 	/////////////////////////////////
   ///////////////////////////////////////////////////////////////
  */
.drawer-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: RGBA(0, 0, 0, 0.5);
  display: grid;
  justify-items: end;
}
.drawer-container.right-drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  background: RGBA(0, 0, 0, 0.5);
  display: grid;
  justify-items: start;
}

.drawer {
  animation: drawer_slide_in 0.15s forwards;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-width: 350px;
  max-width: 1000px;
  height: 100vh;

  background: #fff;
  /* 
  background: rgba(255,255,255,0.9);
  backdrop-filter: blur(10px); */

  border-radius: 5px;
  overflow: hidden;
}

.right-drawer .drawer {
  animation: right_drawer_slide_in 0.15s forwards;
}

.drawer.closing {
  animation: drawer_slide_out 0.15s forwards;
}
.right-drawer .drawer.closing {
  animation: right_drawer_slide_out 0.15s forwards;
}
@keyframes drawer_slide_in {
  from {
    right: -90vw;
  }
  to {
    right: 0px;
  }
}
@keyframes drawer_slide_out {
  from {
    right: 0px;
  }
  to {
    right: -90vw;
  }
}
@keyframes right_drawer_slide_in {
  from {
    left: -90vw;
  }
  to {
    left: 0px;
  }
}
@keyframes right_drawer_slide_out {
  from {
    right: 0px;
  }
  to {
    right: -90vw;
  }
}

.drawer-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 2px;
  align-items: center;
  padding: 15px 15px 15px 0px;
  border-bottom: 1px solid RGBA(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px RGBA(0, 0, 0, 0.12);
  z-index: 9999;
}
.drawer-header .back-button {
  cursor: pointer;
  padding: 2px 15px 0px 20px;
}
.drawer-header .back-button img {
  height: 20px;
  filter: brightness(0);
}
.drawer-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: RGBA(255, 255, 255, 0.1);
  color: #fff;
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
}
.drawer-body {
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
  background: RGBA(0, 0, 0, 0.05);
}

.drawer.noPadding .drawer-body {
  padding: 0px;
}
.drawer.whiteBody .drawer-body {
  background: white;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.drawer.fullPage {
  width: 100vw;
  max-width: 100vw;
}
.drawer.big {
  width: 80vw;
  max-width: 80vw;
}
.drawer.medium {
  width: 50vw;
  max-width: 50vw;
  min-width: 400px;
}
.drawer.small {
  width: 30vw;
  max-width: 30vw;
  min-width: 400px;
}

.drawer-footer {
  padding: 10px 15px;
  box-shadow: 0px 0px 10px RGBA(0, 0, 0, 0.12);
}

.drawer-footer .btn {
  padding: 12px 5px;
}
.drawer-footer-content {
  display: grid;
  grid-template-columns: minmax(100px, 20vw) 1fr minmax(100px, 20vw);
  gap: 10px;
  width: 100%;
}

@keyframes slide_down {
  from {
    top: -20vh;
    opacity: 0.1;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes slide_up {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: -20vh;
    opacity: 0.1;
  }
}

@media screen and (min-width: 80px) and (max-width: 801px),
  screen and (orientation: portrait) {
  .drawer {
    width: 100vw !important;
    max-width: 100vw !important;
    height: calc(100vh - 70px);
    width: 100vw !important;
    min-width: 100vw !important;
  }
  .drawer-body {
    padding: 15px;
  }
  .drawer-footer-content {
    grid-template-columns: 1fr auto 1fr;
  }
}

@supports (-webkit-touch-callout: none) and (not (pointer: coarse)) {
  .drawer {
    height: calc(100vh - 110px);
  }
}

/*
//////////////////////////////////////////////////
////////////////   tabs ////////////////////////
//////////////////////////////////////////////////
*/
.scrollableTabs {
  display: grid;
  grid-auto-columns: 14%;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  padding: 5px 0px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab {
  display: grid;
  place-items: center;
  gap: 5px;
  padding: 15px 0px;
  border-radius: 10px;
  background: white;
  box-shadow: 5px 5px 8px RGBA(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
}

.tab.active {
  box-shadow: 0px 0px 3px transparent;
  background: var(--mainish-bg);

  font-weight: bold;
}

.tab {
  color: rgba(0, 57, 122, 0.8);
}

.tab .icon {
  height: 20px;
  opacity: 0.8;
}
/* .tab.active * {
  color: rgb(0, 119, 255);
} */

.tabContent {
  animation: tabFade 0.4s forwards;
}

@keyframes tabFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade {
  opacity: 0;
  animation: fade 0.7s forwards;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  .mscrollableTabs {
    display: grid;
    grid-auto-columns: 14%;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    padding: 5px 0px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

/*
//////////////////////////////////////////////////
////////////////   Statistics card ////////////////////////
//////////////////////////////////////////////////
*/

.statisticsCard {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.425);
}
.statisticsCard .icon {
  display: grid;
  place-items: center;
  border-radius: 10px;
  padding: 12px;
  font-size: 22px;
  color: white;
}

.statisticsCard.blue .icon {
  background: var(--blue-bg);
}

.statisticsCard.red .icon {
  background: var(--red-bg);
}

.statisticsCard.purple .icon {
  background: var(--purple-bg);
}
.statisticsCard.pink .icon {
  background: rgb(232, 47, 179);
}
.statisticsCard.orange .icon {
  background: var(--orange-bg);
}
.statisticsCard.green .icon {
  background: var(--green-bg);
}

/*
//////////////////////////////////////////////////
////////////////   animations ////////////////////////
//////////////////////////////////////////////////
*/
.shrink {
  overflow: hidden;
  animation: shrink 0.4s forwards ease-out;
}

@keyframes shrink {
  from {
    max-height: 300px;
    opacity: 1;
  }
  to {
    max-height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0.3;
  }
}

/*
//////////////////////////////////////////////////
////////////////   searchBar ////////////////////////
//////////////////////////////////////////////////
*/

.searchBar {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-radius: 10px;
  padding: 0px 10px;
  background: var(--lightergray);
}

.searchBar input {
  border: none;
  outline: none;
  background: transparent;
}
.searchBar img {
  width: 20px;
}

/*
//////////////////////////////////////////////////
////////////////   Uploads ////////////////////////
//////////////////////////////////////////////////
*/

.uploadSpot {
  display: grid;
  place-items: center;
  color: RGBA(0, 0, 0, 0.3);
  padding: 30px;
  min-height: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--gray);
  background: white;
}
.uploadPreview {
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 5px 10px var(--lightgray);
  max-height: 300px;
  max-width: 300px;
  min-height: 100px;
  min-width: 30px;
  position: relative;
}

.uploadPreview .cornerButton img {
  border-radius: 0px;
  height: 20px;
}
.uploadPreview .cornerButton {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 0px;
  top: 15px;
  right: 15px;
  display: grid;
  place-items: center;
  background: RGBA(0, 0, 0, 0.3);
}

.uploadPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  overflow: hidden;
}

.uploadProgress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: RGBA(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  border-radius: 10px;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.uploadCard {
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 5px 10px var(--lightgray);
  max-height: 300px;
  max-width: 300px;
  min-height: 100px;
  min-width: 30px;
  position: relative;
}

.uploadCard .cornerButton img {
  border-radius: 0px;
  height: 20px;
}
.uploadCard img {
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  overflow: hidden;
}

/* .viewer-toolbar li[data-viewer-action="play"],
.viewer-toolbar li[data-viewer-action="reset"],
.viewer-toolbar li[data-viewer-action="flip-horizontal"],
.viewer-toolbar li[data-viewer-action="flip-vertical"] {
  display: none;
} */

.viewer-backdrop {
  z-index: 99999999 !important;
}
.viewer-toolbar > ul > li {
  display: grid;
  place-items: center;
  width: 40px !important;
  height: 40px !important;
  margin: 5px;
}
/*
//////////////////////////////////////////////////
////////////////   Selectable Colors ////////////////////////
//////////////////////////////////////////////////
*/
.selectableColor {
  display: grid;
  place-items: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid RGBA(0, 0, 0, 0.1);
}

.selectableColor.selected {
  border: 3px solid RGBA(0, 0, 0, 0.5);
}

.colorCircle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid RGBA(0, 0, 0, 0.1);
}

/*
//////////////////////////////////////////////////
////////////////   compartment ////////////////////////
//////////////////////////////////////////////////
*/

.compartment.blueish,
.compartment.blue {
  background: rgba(0, 164, 255, 0.15);
  box-shadow: 5px 5px 10px transparent;
}

.compartment {
  height: 100%;

  padding: 20px;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.097);

  display: grid;
  gap: 10px;
  grid-template-rows: auto 1fr;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.425);
}

.compartment .content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.compartment .header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  align-items: center;
}

.compartment .compartmentTitle {
  font-weight: bold;
}

/* 
///////////////////////////////////
//////////////   Chips  //////////////
///////////////////////////////////
*/
.field.horizontal {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 15px;
}

.chips {
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(100px, 1fr));
  gap: 5px;
}

.field.horizontal .chips {
  grid-template-columns: repeat(auto-fill, 130px);
}

.chip {
  display: grid;
  text-align: center;
  place-items: center;
  padding: 12px 5px;
  border-radius: 6px;
  background: RGBA(0, 0, 0, 0.1);
  color: RGBA(0, 0, 0, 0.5);
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  transition: background 0.3s;
}
.chip2 {
  display: grid;
  text-align: center;
  place-items: center;
  padding: 5px 12px;
  border-radius: 15px;
  background: rgba(181, 181, 181, 0.1);
  border: 1px solid RGBA(0, 0, 0, 0.1);
  /* color: RGBA(0, 0, 0, 0.5); */
  font-weight: bold;
}

.chip.active {
  background: var(--mainish-bg);
  color: var(--mainish-color);
}

/* 
///////////////////////////////////
//////////////   Avatar  //////////////
///////////////////////////////////
*/
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  display: inline-block;
  margin: auto;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* 
///////////////////////////////////
//////////////  Card  //////////////
///////////////////////////////////
*/

.card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: var(--bluegray-bg);
  box-shadow: 5px 5px 10px var(--bluegray-bg);
  position: relative;
  z-index: 1;
}

.card.blueish {
  background: rgba(0, 164, 255, 0.1);
}
.card.blue {
  background: rgba(0, 164, 255);
  color: white;
}

.card.gray {
  background: rgba(0, 0, 0, 0.07);
  box-shadow: none;
  color: RGBA(0, 0, 0, 0.8);
}

.card.grayish {
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}

.card.active {
  background: var(--main-bg);
  color: white;
}

.cornerButton {
  position: absolute;
  padding: 10px;
  top: 5px;
  right: 5px;
  z-index: 99;
  cursor: pointer;
  background: transparent;
  border-radius: 100%;
  opacity: 1;
}

.cornerButton.secondary {
  right: 40px;
}
.cornerButton.cross {
  top: -10px;
  right: -10px;
  background: #f3f3f3;
  padding: 5px 5px 2px 5px;
  box-shadow: 0px 0px 10px RGBA(0, 0, 0, 0.1);
}

.cornerButton:hover {
  opacity: 1;
  background: var(--lightgray);
}

.cornerButton.cross:hover {
  background: #cbcbcb;
}

.card .cornerButton {
  opacity: 0;
}

.input > .cornerButton {
  opacity: 1;
}

.cornerButton img {
  height: 15px;
}

.tab:hover > .cornerButton,
.card:hover > .cornerButton {
  opacity: 1;
}

.cornerButton.loading {
  opacity: 1 !important;
}

@media screen and (max-width: 800px) {
  .card .cornerButton {
    opacity: 1;
  }
}

/* 
///////////////////////////////////
//////////////  ITEM CARD  //////////////
///////////////////////////////////
*/

.itemCard.empty {
  background: white !important;
  box-shadow: 5px 5px 10px var(--lightgray) !important;
}
.itemCard {
  display: grid;

  /* align-items: center; */
  gap: 15px;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 5px 5px 10px var(--lightgray);
  position: relative;
  overflow: hidden; /* Ensures background stays within the card */
}

.card2 {
  border: 1px solid var(--lightgray);
  border-radius: 15px;
  padding: 10px;
  background: rgba(249, 84, 84, 0.037);
}

.itemCardBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: var(--card-background, rgba(239, 239, 239, 0.532));
  filter: blur(8px);
  z-index: 0;
}

.itemCardContent {
  position: relative;
  z-index: 1;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.itemCard.loading {
  opacity: 0.5;
}

.itemCard .icon {
  font-size: 18px;
  padding: 5px;
  border-radius: 8px;
  /* background-color: RGBA(0, 0, 0, 0.03); */
}

.itemCard > .cornerButton {
  opacity: 0;
}
.itemCard:hover > .cornerButton {
  opacity: 1;
}

/* 
///////////////////////////////////
//////////////  Loading spinner  //////////////
///////////////////////////////////
*/

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 
///////////////////////////////////
//////////////  Expansion tile  //////////////
///////////////////////////////////
*/
.expansionTile {
  background: RGBA(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-template-rows: auto 0fr;
  align-content: start;
  transition: grid-template-rows 0.3s;
}

.expansionTile.expanded {
  grid-template-rows: auto 1fr;
}

.expansionTileHeader {
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 5px;
  align-items: center;
}

.expansionTileHeader img {
  max-height: 15px;
  max-width: 15px;
  transition: rotate 0.3s;
}

.expansionTile.expanded > .expansionTileHeader .expansionChevron {
  rotate: 180deg;
}

.expansionTileContent > div {
  padding: 0px 10px 10px 10px;
}
.expansionTileContent {
  overflow: hidden;
}

/*
///////////////////////////////////
//////////////  CALENDAR  //////////////
///////////////////////////////////
*/
.fc-icon {
  display: none;
}
.fc-datagrid-cell-frame {
  position: relative;
}
.fullCalendar {
  color: #7a7a7a;
  background: #fff;
  width: 100%;
  display: inline-block;
}

.fullCalendar * {
  border-color: rgb(241, 241, 241) !important;
}

.fullCalendar button {
  background: white !important;
  box-shadow: 5px 5px 10px var(--lightgray);
  color: RGBA(0, 0, 0, 1) !important;
}

.fullCalendar .fc-resource {
  text-transform: capitalize;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
.fullCalendar .fc-button-active {
  box-shadow: 5px 5px 10px var(--gray);
  color: rgb(0, 164, 255) !important;
}

.fc-event,
.fc-timegrid-event .fc-event-main {
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 3px !important;
  overflow: hidden;
  box-shadow: 3px 3px 10px var(--gray);
}
.nightshift-lane {
  background: rgba(0, 164, 255, 0.1);
}

.fc-col-header-cell-cushion {
  width: 100%;
}

.dayHeader {
  width: 100%;
  padding: 10px 5px 10px 10px;
  text-align: left;
  font-weight: normal;
  color: var(--darkgray);
}
.dayHeader strong {
  font-size: 20px;
  color: RGBA(0, 0, 0, 0.65);
}

@media screen and (min-width: 800px) {
  .dayHeader:not(:hover) .iconButton {
    display: none !important;
  }
}

.fullCalendar a {
  border-radius: 0px;
  border: none;
}

/* 

.fullCalendar .my-event {
  color: #4a4a4a;
  border-top: 1px solid RGBA(255, 255, 255, 0.08);
}
.fullCalendar .fc-title {
  text-align: center;
  padding-top: 5px;
  text-transform: capitalize;
}


.fullCalendar .week-header-column {
  padding: 10px 20px;
  text-align: left;
  position: relative;
}
.fullCalendar .week-header-column.disabled {
  opacity: 0.4;
}
.fullCalendar .gray {
  color: rgb(199, 199, 199);
}
.fullCalendar .header-day {
  font-weight: bold;
  font-size: 20px;
  margin-top: 5px;
}
.fullCalendar .fc-today {
  background: RGBA(0, 0, 0, 0.02) !important;
}
.fullCalendar .fc-today * {
  color: rgba(0, 153, 255, 0.933);
}
.fullCalendar .fc-time {
  color: rgb(182, 182, 182);
}
.fullCalendar .fc-axis.fc-time span {
  position: relative;
  top: 3px;
}
.fullCalendar thead {
  box-shadow: 0px 5px 5px 0px RGBA(0, 0, 0, 0.03);
}
.fullCalendar .fc-minor td.fc-widget-content {
  border: none;
}
.fullCalendar .fc-toolbar.fc-header-toolbar {
  display: none;
}
.fullCalendar .fc-bgevent {
  background: RGBA(0, 0, 0, 0.05);
} */

/*
///////////////////////////////////
//////////////  REOERDERABLE  //////////////
///////////////////////////////////
*/

.infiniteList {
  height: 100%;
  /* overflow-y: auto; */
}

/*
///////////////////////////////////
//////////////  REOERDERABLE  //////////////
///////////////////////////////////
*/

.emptyDraggedOver {
  background: RGBA(0, 0, 0, 0.1);
  border-radius: 10px;
}
.droppable.dragged {
  opacity: 0.15;
}
.droppable {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  /* background: RGBA(0, 0, 0, 0.05); */
}

.droppable.horizontal {
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
}

.dragHint {
  background: RGBA(0, 0, 0, 0);
  width: 0px;
  height: 0px;
}

.dragHint:not(.horizontal) {
  transition: height 0.15s;
}

.dragHint.horizontal {
  transition: width 0.15s;
}

.dragHint.open {
  width: 100%;
  height: 40px;
}

.dragHint.horizontal.open {
  height: 100%;
  width: 40px;
}

.dragHint.open.animateClose {
  height: 0px;
}

.reordered {
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 206, 51, 0.5);
  border-radius: 10px;
  opacity: 0.4;
  animation: reorderedAnimation 8s forwards;
}

@keyframes reorderedAnimation {
  from {
    border-color: rgba(0, 206, 51, 0.5);
  }
  to {
    border-color: transparent;
  }
}

table {
  border-collapse: collapse;
}

table th {
  border: 1px solid RGBA(0, 0, 0, 0.2);
  padding: 10px;
}
table td {
  border: 1px solid RGBA(0, 0, 0, 0.2);
  padding: 10px;
}

/*
///////////////////////////////////
//////////////  SPARKS  //////////////
///////////////////////////////////
*/
.blueSpark {
  animation: blueSpark 2s infinite ease-out;
}
.redSpark {
  animation: redSpark 2s infinite ease-out;
}
.orangeSpark {
  animation: orangeSpark 2s infinite ease-out;
}
@keyframes blueSpark {
  0% {
    background: rgb(0, 164, 255, 0.9);
  }
  50% {
    background: rgb(0, 164, 255, 0.3);
    color: #fff;
  }
  100% {
    background: rgb(0, 164, 255, 0.9);
  }
}
@keyframes redSpark {
  0% {
    background: rgb(255, 42, 42);
  }
  50% {
    background: rgba(255, 71, 71, 0.54);
    color: #fff;
  }
  100% {
    background: rgb(255, 42, 42);
  }
}
@keyframes orangeSpark {
  0% {
    background: rgb(255, 103, 27);
  }
  50% {
    background: rgba(255, 110, 38, 0.633);
    color: #fff;
  }
  100% {
    background: rgb(255, 103, 27);
  }
}

/*
///////////////////////////////////
//////////////  Daypicker  //////////////
///////////////////////////////////
*/

.daypicker {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 10px;
  background: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 5px 5px 10px RGBA(0, 0, 0, 0.1);
}
.daypicker input {
  background: transparent;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: bold;
}
/* .daypicker.today input{color:} */

/*
///////////////////////////////////
//////////////  Staggered  //////////////
///////////////////////////////////
*/

.staggered {
  position: relative;
  opacity: 0;
  animation: staggeredAnimation 0.2s forwards ease-in-out;
}

@keyframes staggeredAnimation {
  from {
    top: -5vh;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

.jauge {
  display: grid;
  place-items: start;
  width: 100%;
  height: 10px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  background: RGBA(0, 0, 0, 0.1);
}

.jauge > div {
  display: inline-block;
  background: var(--main-bg);
  height: 100%;
  border-radius: 10px;
}
body {
  margin: 0;
  height: 100vh;
  background: linear-gradient(135deg, #f8f9fc, #e8edf9); /* Soft gradient */
  overflow: hidden;
  position: relative;
}

/* //////////////////////////////////////////////////
////////////////   BACKGROUND  ////////////////////////
//////////////////////////////////////////////////*/

.background-shape {
  position: absolute;
  border-radius: 50%;
  filter: blur(100px);
  animation: float 10s ease-in-out infinite;
  z-index: 1;
}

.shape-1 {
  width: 400px;
  height: 400px;
  background: rgba(102, 117, 255, 0.2);
  top: 0%;
  left: 5%;
  z-index: 0;
}

.shape-2 {
  width: 500px;
  height: 500px;
  background: rgba(255, 102, 204, 0.226);
  top: 50%;
  z-index: 0;
  right: 0%;
}

.shape-3 {
  width: 250px;
  height: 250px;
  background: rgba(153, 185, 255, 0.5);
  bottom: 20%;
  right: 5%;
  z-index: 0;
}

.shape-4 {
  width: 250px;
  height: 250px;
  background: rgba(255, 153, 226, 0.2);
  bottom: 30%;
  left: 20%;
  z-index: 0;
}

.shape-5 {
  width: 450px;
  height: 450px;
  background: rgba(255, 153, 226, 0.196);
  bottom: 0%;
  left: 0%;
  z-index: 0;
}
/* Floating animation for shapes */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media screen and (max-width: 1000px) {
  .shape-1 {
    width: 200px;
    height: 200px;
  }

  .shape-2 {
    width: 200px;
    height: 200px;
  }
  .shape-3 {
    width: 200px;
    height: 200px;
  }

  .shape-4 {
    width: 200px;
    height: 200px;
  }

  .shape-5 {
    width: 200px;
    height: 200px;
  }
}

.profilComp {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  place-items: center;
}

@media (max-width: 800px) {
  .profilComp {
    display: block;
    width: 100%;
  }
  .profilComp .compartment {
    width: 100%;
    height: auto;
  }
}
