#landingPage .dark-gray {
  color: rgba(14, 58, 115, 0.75);
}

#landingPage {
  /* font-family: "montserrat", sans-serif; */
  font-size: 15px;
  overflow-y: scroll;
  height: 100vh;
  background: rgba(0, 166, 255, 0.05);
  padding-top: 30px;
}

#landingPageContent {
  max-width: 1150px;
  padding: 0px 30px;
  margin: 0 auto;
  display: grid;
  gap: 50px;
}

#landingPage .title {
  font-size: 30px;
  font-weight: bold;
}

#landingPage .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 5px 5px 10px var(--lightgray);
  background: white;
}

#landingPage .headerContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 15px 20px;
  max-width: 1150px;
  margin: 0 auto;
  width: 100%;
  z-index: 9;

  z-index: 2;
}

#landingPage .header-button:hover {
  color: black;
  cursor: pointer;
}

.header.mobile {
  display: none;
}

.hero-section {
  display: grid;
  padding: 20px 0px;
  align-items: center;
  gap: 50px;
}

.hero-section h1 {
  font-size: 40px;
}

.hero-section h2 {
  font-size: 30px;
  font-weight: normal;
}

.staggered {
  animation: ladingPageStaggeredAnimation 0.7s forwards ease-out;
}

.features {
  display: grid;
  gap: 100px;
}

.feature {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}

.desktop-screenshot {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: RGBA(0, 0, 0, 0.1);
  border-radius: 20px;
  box-shadow: 0px 0px 10px var(--lightgray);
}
.mobile-screenshot {
  width: 150px;
  height: 280px;
  background-color: RGBA(0, 0, 0, 0.1);
  border-radius: 20px;
  box-shadow: 0px 0px 10px var(--lightgray);
  position: absolute;
  bottom: -40px;
  right: 30px;
}

.download-section {
  display: grid;
  place-items: center;
  padding: 50px 0px;
  color: white;
  padding: 20px;
  min-height: 500px;
  border-radius: 20px;
  background-image: linear-gradient(
      45deg,
      rgba(66, 245, 227, 0.75),
      rgba(8, 83, 156, 0.75)
    ),
    url("/images/doctors-background.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.download-section .white-btn {
  color: rgb(0, 160, 172);
}

@keyframes ladingPageStaggeredAnimation {
  from {
    top: -10vh;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

.specialitiesList {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}

/*
//////////////////////////////////////////////////
////////////////   Footer ////////////////////////
//////////////////////////////////////////////////
*/

.footer {
  background: rgba(0, 147, 166, 0.1);
}

.footer h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-content {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  padding: 60px 40px;
  justify-content: space-between;
}

.footer-bottom {
  text-align: center;
  background: RGBA(0, 0, 0, 0.03);
  padding: 40px;
}

/*
//////////////////////////////////////////////////
////////////////   Mobile Only  ////////////////////////
//////////////////////////////////////////////////
*/

@media screen and (max-width: 800px) {
  .specialitiesList {
    display: grid;
    grid-template-columns: inherit;
    grid-auto-columns: 30%;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    padding: 5px 0px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  #landingPage .header.desktop {
    display: none;
  }

  #landingPage .header.mobile {
    display: grid;
  }

  .feature {
    grid-template-columns: 1fr;
  }
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
     gap:30px;
  }
}

/*
//////////////////////////////////////////////////
////////////////   Desktop Only ////////////////////////
//////////////////////////////////////////////////
*/

@media screen and (min-width: 800px) {
  .screenshotsFirst > div:nth-child(1) {
    order: 2;
  }
}
