
#betaBand {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  display: grid;
  place-items: center;
  color: rgba(255, 99, 99, 1);
  background: rgba(255, 99, 99, 0.2);
  font-weight: bold;
}

#softwareUpdateToast {
  position: fixed;
  bottom: 15px;
  left: 0px;
  width: 100vw;
  display: grid;
  place-items: center;
}

#softwareUpdateToast > div {
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 15px;
  gap: 20px;
  align-items: center;
  background: white;
  text-align: left;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px RGBA(0, 0, 0, 0.1);
}

.page {
  /* background: rgba(255,255,255,0.8); */
  
  height: 100vh;
  overflow-y: scroll;
}

.navbarPage {
  align-items: start;
  align-content: start;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0px;
  backdrop-filter: blur(50px); 

}

.navbarPage .pageContent {
  height: 100vh;
  /* padding: 20px; */
}

.logo {
  height: 70px;
}

@media screen and (max-width: 800px) {
  .navbarPage {
    grid-template-columns: 1fr;
  }
  .navbarPage .pageContent {
    height: calc(100vh - 60px);
  }
}

.circular-image {
  display: inline-block;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 10px var(--lightgray);
}
.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backgroundCircle {
  display: none;
  position: fixed;
  top: 20px;
  left: -30px;
  border-radius: 100%;
  height: 400px;
  width: 400px;
  background: var(--main-bg);
  z-index: -1;
}

.backgroundCircle.red {
  background: var(--red-bg);
  left: inherit;
  right: 20px;
  top: 100px;
  height: 150px;
  width: 150px;
}

.whiteBlurredGlass {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  height: 97vh;
  width: 210px;
  border-radius: 20px;
  z-index: 9;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.097); 
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  grid-template-rows: auto 1fr;
  gap: 20px;
  margin: 10px;
  padding: 15px;

  overflow: hidden;
  overflow-y: auto;
  backdrop-filter: blur(3px); 
  background-color: rgba(255, 255, 255, 0.425); 

}

.navbarCount {
  display: grid;
  place-items: center;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  font-size: 12px;
  background: var(--red-bg);
  color: white;
  position: absolute;
  top: 0px;
  left: 18px;
}

.navbar-button {
  display: grid;
  grid-template-columns: 30px 1fr;
  position: relative;
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  text-align: left;
  border: none;
  align-items: center;
  cursor: pointer;
}

.navbar-button.disabled {
  opacity: 0.3;
}

.navbar-button img {
  width: 20px;
}

.navbar-button:hover {
  background: var(--bluegray-bg);
}
.navbar-button.active {
  background: var(--main-bg);
  color: white;
}
.navbar-button.active img {
  filter: brightness(100);
}

#patientSelectDrawer .drawer-body {
  padding: 0px;
  overflow: hidden;
}
#patients_page {
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: 300px 1fr;
  height: 100%;
}

.historyTabs .tab {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}
/* 
.historyTabs::-webkit-scrollbar {
  display: none;
} */

.historyTabs {
  /* display: grid;
  grid-auto-columns: 14%;
  grid-auto-flow: column;
  grid-column-gap: 10px; */
  width: 100%;
  padding: 5px 0px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
}

.historyTabs .tab {
  display: inline-block;
  padding: 15px 10px;
  margin-right: 10px;
  min-width: 100px;
}
.unreadCircle {
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  font-size: 12px;
  background: var(--red-bg);
  color: white;
  position: absolute;
  top: -8px;
  left: 0px;
  z-index: 999;
}

.unreadCircle.right {
  left: inherit;
  right: 0px;
}

#dicomIframe {
  width: 90vw;
  height: 80vh;
  border: none;
}

@media screen and (min-width: 80px) and (max-width: 801px),
  screen and (orientation: portrait) {
  #root {
    grid-template-columns: 1fr;
  }

  .navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    z-index: 3;
    box-shadow: none;
    border:0px;   
    padding: 0px 5px;
    align-items: center;
    padding: 5px;
  }

  #navbar {
    align-items: center;
  }

  #navbar > div {
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, 1fr);
    overflow-x: auto;
  }

  #navbar > div::-webkit-scrollbar {
    display: none;
  }

  .navbar-button {
    grid-template-columns: 1fr;
    gap: 5px;
    text-align: center;
    /* min-width: 110px; */
  }

  .page,
  #patients_page {
    height: calc(100vh - 80px);
    margin-top: 80px;
  }

  #patients_page {
    grid-template-columns: 1fr;
  }

  #loginPage {
    /* padding-top: 100px; */
    margin-top: 0px;
    height: 100vh;
  }
  #loginHeader {
    display: none;
  }

  #mobileAddEcg {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    background: white;
    box-shadow: 5px 5px 20px RGBA(0, 0, 0, 0.1);
    padding: 15px;
  }

  #networkHealthChecker {
    display: none;
  }

  #dicomIframe {
    width: 90vw;
    height: 70vh;
    border: none;
  }

  .historyTabs {
    display: grid;
    grid-auto-columns: 28%;
  }
}

/*
//////////////////////////////////////////////////
////////////////   PATIENT LIST  ////////////////////////
//////////////////////////////////////////////////
*/
#patients_list_container {
  /* background: white; */
  height: 100vh;
  overflow: hidden;
  padding: 10px;
}

#patients_search {
  padding: 10px;
  border-bottom: 1px solid var(--bluegray-bg);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.patient_item {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  gap: 3px;
  padding: 15px;
  cursor: pointer;
}

.patient_item:hover {
  background: var(--bluegray-bg);
}

.patient_item.active {
  background: var(--lightergray);
}
.patient_item.active div {
  background: var(--main-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*
//////////////////////////////////////////////////
////////////////   WAITING SCREEN  ////////////////////////
//////////////////////////////////////////////////
*/

#waitingScreenDrawer .drawer-header {
  background: #18283c;
  color: white;
}
#waitingScreenDrawer .drawer-content {
  background: #18283c;
}
#waitingScreenDrawer .drawer-footer {
  background: #18283c;
}
#waitingScreenDrawer {
  background: #18283c;
}

#waitingScreenDrawer .nextTicket {
  font-size: 45px;
  padding: 50px 0px;
}

#waitingScreenDrawer .waitingScreenBox {
  background: RGBA(0, 0, 0, 0.2);
  color: white;
  min-height: 260px;
  padding: 30px;
  border-radius: 15px;
}

.waitingScreenBox .title {
  font-size: 30px;
  font-weight: bold;
}

#waitingScreenDrawer .compartment .title {
  font-size: 30px;
}

#waitingScreenDrawer .gray {
  color: RGBA(255, 255, 255, 0.4);
  font-size: 20px;
  padding: 50px 0px;
  text-align: center;
}
.currentAppointment {
  background: var(--main-bg) !important;
  color: white !important;
}
/* .currentAppointment.green{
  background: var(--green-bg) !important;
}
.currentAppointment.orange{
  background: var(--orange-bg) !important;
  color: white;
}
.currentAppointment.red{
  background: var(--red-bg) !important;
  color: white;
} */

.currentAppointment > div {
  display: grid;
  gap: 5px !important;
  grid-template-columns: 1fr;
}
.currentAppointment * {
  color: white;
}

.emergencyChip {
  border-radius: 100px;
  padding: 10px;
  margin-top: 10px;
  display: inline-block;
}

/*
//////////////////////////////////////////////////
////////////////   CONSULTATION  ////////////////////////
//////////////////////////////////////////////////
*/
#waitingScreenDrawer,
#consultationDrawer,
#hospitalisationDrawer {
  width: 100vw;
  max-width: 100vw;
}

.scrollableChips {
  display: grid;
  grid-auto-columns: 40%;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  padding: 5px 0px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tabsPage {
  display: grid;
  grid-template-rows: auto minmax(100px, 1fr);
  gap: 15px;
  height: 100%;
  overflow: hidden;
}

.tabsPage .tabContent {
  height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: minmax(100px, 1fr);
  padding-bottom: 10px;
}

#serviceInformationDrawer .usersList {
  grid-template-columns: 1fr;
}
.bed {
  min-height: 180px;
  padding: 20px !important;
}
.bed.available {
  background: RGBA(0, 0, 0, 0.07);
  box-shadow: none;
}

@media screen and (min-width: 80px) and (max-width: 801px),
  screen and (orientation: portrait) {
  .tabsPage .tabContent {
    height: auto;
    overflow-y: scroll;
    grid-template-rows: auto;
  }
}

/*
//////////////////////////////////////////////////
////////////////   PATIENT DASHBOARD  ////////////////////////
//////////////////////////////////////////////////
*/

#patient_dashboard {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
  /* grid-template-rows: auto auto auto; */
  gap: 20px;
  height: 100vh;
  padding-bottom: 100px;
  overflow-y: scroll;
}

#explorationsPageContent {
  padding: 20px;
  height: 100vh;
  padding-bottom: 100px;
  overflow-y: scroll;
}

#patient_history {
  grid-column: span 3;
}

.infoGrid {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 5px;
}

.patient_antecedent {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: var(--mainish-bg);
}

#antecedents_drawer .compartment .title {
  text-align: center;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 10px 0px;
  text-transform: capitalize;
}

.last-inserted {
  background: rgb(20, 158, 78);
}

.reference .iconButton {
  height: 25px;
  width: 25px;
}

.reference.active,
.reference.expanded {
  background: var(--main-bg);
  color: #fff;
}

.reference-title {
  text-align: left;
}
.reference.active .expansionTileHeader {
  grid-template-columns: auto 1fr auto;
  gap: 5px;
  padding: 8px;
}

.reference.active .expansionTileHeader img,
.reference.expanded .expansionTileHeader img {
  filter: brightness(100);
}

.reference.active .iconButton:not(.iconButton:hover) {
  background: transparent;
}
/* .reference.active .field * {
  color: black;
} */

.errorMessage {
  color: red;
}

.errorField {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  background: rgba(255, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px;
}

.field {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.field.hasFieldUnit input,
.field.hasFieldUnit select,
.field.hasFieldUnit .input {
  border-radius: 5px 0px 0px 5px;
}

.fieldUnit {
  background: RGBA(0, 0, 0, 0.08);
  color: gray;
  padding: 0px 10px;
  border-radius: 0px 5px 5px 0px;
  display: grid;
  place-items: center;
}

.field .label {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  font-size: 13px;
  opacity: 0.8;
}

.field .label .iconButton {
  padding: 5px;
  height: auto;
  width: auto;
  font-size: 15px;
}

.user_card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: white;

  box-shadow: 5px 5px 10px var(--lightgray);
}

.userMiniCard {
  display: inline-block;
  align-items: center;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
}

.userMiniCard:hover {
  background: white;
  box-shadow: 5px 5px 10px var(--lightgray);
}
.userMiniCard .left {
  margin-right: 10px;
}
.userMiniCard > * {
  display: inline-block;
  vertical-align: middle;
}

/*
//////////////////////////////////////////////////
////////////////   Ordonnance  ////////////////////////
//////////////////////////////////////////////////
*/
.ordonnanceLine {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 5px;
}

.treatmentResource .cornerButton {
  top: 0px;
  right: -5px;
  box-shadow: none;
}

.treatmentEvent {
  padding: 10px 0px;
  background: white;
  color: RGBA(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 12px;
  overflow: auto;
}
.treatmentEvent.loading {
  opacity: 0.5;
}

.treatmentEvent.finished {
  background: var(--green-bg);
  color: white;
}
/*
//////////////////////////////////////////////////
////////////////   QUILL  ////////////////////////
//////////////////////////////////////////////////
*/

#messageriePage {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100vh;
}

.messagerieUsersList {
  height: 100%;
  overflow-y: auto;
}
.messagerieUser {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.messagerieUser.active {
  background: var(--lightergray);
}

.messagesSection {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

.messagesSection .messagerieHeader {
  box-shadow: 0px 0px 10px var(--lightgray);
  padding: 20px;
}

.messagerieHeader {
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 14px;
}

.messagesSection .content {
  padding: 20px;
  background: RGBA(0, 0, 0, 0.05);
  overflow-y: scroll;
}

.message > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  align-items: start;
  place-items:  start;
}

.message {
  display: grid;
  place-items: start;
  
}

.message.sent {
  place-items: end;
}

.message.sent .messageBubble {
  background: var(--lightgray);
  color: inherit;
}

.messageBubble {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 10px;
  border-radius: 10px 0px 10px 10px;
  background: var(--main-bg);
  
  color: white;
}

.messageBubble.right{
  border-radius: 10px 0px 10px 10px;

}
.messageBubble.left{
  border-radius:00px 10px 10px 10px;

}

.messageBubble img {
  max-width: 100%;
  max-height: 150px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px var(--lightgray);
  cursor: pointer;
}

.messagesSection .footer {
  box-shadow: 0px 0px 10px var(--lightgray);
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
}


.correctAnswer{
  background: rgba(2, 194, 75, 0.2);
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  line-height: 25px;
  border: 1px solid rgba(2, 194, 75, 0.2);
}
.resume{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(86, 86, 86, 0.2);
}
@media screen and (min-width: 80px) and (max-width: 801px),
  screen and (orientation: portrait) {
  #messageriePage {
    grid-template-columns: 1fr;
  }
}

/*
//////////////////////////////////////////////////
////////////////   ECG platform  ////////////////////////
//////////////////////////////////////////////////
*/

.ecgCard .button-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  padding: 10px;
}

.mainUploadContainer {
  background: RGBA(0, 0, 0, 0.05);
  width: 100%;
  padding: 5px;
  box-shadow: 5px 5px 10px var(--lightgray);
  border-radius: 8px;
  min-height: 100px;
}

.mainUploadContainer img {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  /* min-height:100px; */
  border-radius: 6px;

  object-fit: cover;
}

.ecgCard .card {
  padding: 8px;
  text-align: left;
  place-items: start;
  align-items: center;
  align-content: center;
}

/*
//////////////////////////////////////////////////
////////////////   RECAP  ////////////////////////
//////////////////////////////////////////////////
*/

#recap-elements {
  margin: auto;
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 150px;
}

.recap-element {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  margin: 10px;
  box-shadow: 10px 10px 20px RGBA(0, 0, 0, 0.08);
  border-radius: 100%;
  padding: 20px;
  height: 130px;
  width: 130px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}
.recap-element:hover {
  box-shadow: 10px 10px 20px RGBA(0, 0, 0, 0.1);
  opacity: 1;
}
.recap-element img {
  height: 50px;
  margin-bottom: 0px;
}

.parcours-element {
  position: static;
  border-radius: 15px;
  padding: 15px;
  display: inline-block;
  z-index: 99;
  opacity: 1;
}
.parcours-element img {
  height: 40px;
}

#central-recap-element {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.admin-recap-element {
  width: 300px;
  margin: auto;
  font-size: 16px;
  margin-bottom: 30px;
  border-radius: 15px;
  font-weight: bold;
  box-shadow: 10px 10px 20px RGBA(0, 0, 0, 0.08);
  padding: 20px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}
.admin-recap-element:hover {
  box-shadow: 10px 10px 20px RGBA(0, 0, 0, 0.1);
  opacity: 1;
}
.admin-recap-element img {
  height: 50px;
  margin-bottom: 5px;
}

/*
//////////////////////////////////////////////////
////////////////   Death certificate  ////////////////////////
//////////////////////////////////////////////////
*/
.border-section {
  border: 1px solid RGBA(0, 0, 0, 0.3);
  padding: 20px;
  margin-top: 5px;
  border-radius: 5px;
}
.border-section2 {
  border: 2px solid RGBA(0, 0, 0, 0.4);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 1px;
}
.checkbox-grid {
  display: grid;
  grid-template-columns: 15px auto;
  gap: 10px;
  align-items: center;
}
.my-checkbox {
  border: 2px solid RGBA(0, 0, 0, 0.4);
  width: 15px;
  height: 13px;
  color: transparent;
  text-align: center;
  display: inline-block;
}
.my-checkbox.checked {
  color: inherit;
  font-weight: bold;
  font-size: 9px;
  padding-bottom: 3px;
}
.subtext {
  font-size: 10px;
  color: RGBA(0, 0, 0, 0.8);
}
.border-title {
  text-align: center;
  width: 100%;
  padding: 5px;
  font-size: 20px;
  border: 1px solid rgb(128, 128, 128);
  margin-top: 30px;
  margin-bottom: 30px;
}

/*
//////////////////////////////////////////////////
////////////////   QUILL  ////////////////////////
//////////////////////////////////////////////////
*/
.a4 {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Hide overflow content */
  outline: none; /* Remove focus outline */
}

/* Style for the container of pages */
.editable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style for printing */
@media print {
  html,
  body {
    overflow: inherit;

  }

  .a4 {
    box-shadow: none;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    padding: initial;
    overflow: hidden;
    page-break-after: always;
  }

  .editable-container {
    display: block; /* Reset flex display for printing */
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 20px;
  }
}

.htmlArea {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.htmlArea #documentEditorToolbar {
  background: transparent;
  grid-template-columns: repeat(6, 1fr);
}
.htmlArea .documentContent {
  padding: 10px;
  min-height: 150px;
}

.htmlArea #documentEditor {
  height: auto;
}

.htmlArea .formatting-btn,
.htmlArea #documentEditorToolbar select {
  background: white;
  box-shadow: 2px 2px 5px RGBA(0, 0, 0, 0.02);
}

#documentEditorDialog {
  width: auto;
  max-width: 100vw;
  background: transparent;
  max-height: 97vh;
}

#documentEditorDialog .title {
  font-weight: bold;
  font-size: 18px;
  padding: 20px;
}

#documentEditorDialog .dialog-body {
  overflow: auto;
  padding: 0px;
  width: auto;
  background: transparent;
}

#documentEditorContainer {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
}

#documentEditor {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  background: white;
  padding: 5px;
  border-radius: 5px;
  color: black;
}

#documentEditorToolbar {
  gap: 10px;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  overflow-y: scroll;
}

#documentEditorToolbar > * {
  vertical-align: middle;
  display: inline-block;
  min-width: 50px;
  margin-right: 5px;
}

.documentEditorPage {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 3px;
  border: 1px solid RGBA(0, 0, 0, 0.1);
}

.documentBody {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
}

.documentLeftBar {
  height: 100%;
  border-right: 1px solid RGBA(0, 0, 0, 0.1);
  display: grid;
  padding: 10px;
}

.documentTitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

.documentContent {
  position: relative;
  padding: 20px;
  min-height: 200px;
}

.documentBackgroundImage {
  position: absolute;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/*
//////////////////////////////////////////////////
////////////////   Theme colors  ////////////////////////
//////////////////////////////////////////////////
*/

.themeColor-red path {
  fill: rgba(255, 99, 99, 0.2);
}

.themeColor-red .documentTitle {
  color: rgba(255, 99, 99, 1);
}

/*
//////////////////////////////////////////////////
////////////////   Header  ////////////////////////
//////////////////////////////////////////////////
*/

.documentHeader {
  border-bottom: 1px solid RGBA(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 110px 1fr 110px;
  place-items: center;
  text-align: center;
  padding: 20px 30px;
  position: relative;
  z-index: 1;
}

.documentHeader img {
  height: 70px;
}

.header-textLeft {
  place-items: inherit;
  align-items: center;
  text-align: left;
  grid-template-columns: 1fr auto;
}

.header-textRight {
  place-items: inherit;
  align-items: center;
  text-align: right;
  grid-template-columns: auto 1fr;
}
.header-textCenter {
  place-items: center;
  align-items: center;
  text-align: center;
  grid-template-columns: auto;
}

.header-textLeftAndRightImageCenter {
  place-items: inherit;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
}

.theme-curvy .documentHeader {
  margin-bottom: 30px;
  border-bottom: none;
}

.curvyHeaderBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 110%;
  height: 100%;
  z-index: -1;
  transform: scale(1.4);
}

/*
//////////////////////////////////////////////////
////////////////   Footer  ////////////////////////
//////////////////////////////////////////////////
*/

.documentFooter {
  border-top: 1px solid RGBA(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 0;
}

.shouldFixFooter .documentFooter {
  position: fixed;
  width: 100%;
}

.footer-textRight {
  grid-template-columns: 100px 1fr;
  text-align: right;
  align-items: center;
}

.documentFooter img {
  height: 70px;
}

.theme-curvy .documentFooter {
  margin-top: 90px;
  border: none;
}

.curvyFooterBackground {
  position: absolute;
  top: -150px;
  left: 0px;
  z-index: -1;
}

.footer-textLeft {
  place-items: inherit;
  align-items: center;
  text-align: left;
  grid-template-columns: 1fr auto;
}

.footer-textRight {
  place-items: inherit;
  align-items: center;
  text-align: right;
  grid-template-columns: auto 1fr;
}
.footer-textCenter {
  place-items: center;
  align-items: center;
  text-align: center;
  grid-template-columns: auto;
}

.footer-textLeftAndRightImageCenter {
  place-items: inherit;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
}

/*
//////////////////////////////////////////////////
////////////////   Toolbar  ////////////////////////
//////////////////////////////////////////////////
*/

.formatting-btn,
#documentEditorToolbar select {
  display: grid;
  place-items: center;
  user-select: none;
  background: RGBA(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 8px 0px;
  cursor: pointer;
  text-align: center;
}
#documentEditorToolbar select {
  padding: 11px 0px;
}

.formatting-btn:active {
  background: RGBA(0, 0, 0, 0.2);
}

.formatting-btn img {
  height: 21px;
}

.documentVariable {
  border-radius: 10px;
  padding: 2px 3px;
  background: RGBA(0, 0, 0, 0.15);
}

.treatment-record {
  padding: 6px 0px;
  display: grid;
  grid-template-columns: 3fr auto auto auto;
  gap: 20px;
}

.feedComment {
  border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
}
.postToolbar {
  border-top: 1px solid RGBA(0, 0, 0, 0.06);
  border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
  padding: 5px 0px;
}
.postToolbar .btn {
  background: transparent;
  border-right: 1px solid RGBA(0, 0, 0, 0.05);
  color: #0080ff;
}

body .react-calendar {
  border: none;
  box-shadow: 5px 5px 10px RGBA(0, 0, 0, 0.1);
  border-radius: 8px;
}
body .react-calendar__navigation__arrow {
  font-size: 25px;
}
body .react-calendar__tile--now {
  background: var(--mainish-bg) !important;
}
body .react-calendar__month-view__days {
  padding-bottom: 10px;
}
body .react-calendar__month-view__days__day--neighboringMonth {
  color: RGBA(0, 0, 0, 0.4) !important;
}
body .react-calendar__month-view__days__day {
  border-radius: 5px;
}
body .react-calendar__tile--active {
  background: var(--blue-bg) !important;
  color: white !important;
  border-radius: 5px;
}

/* ################# ADDED BY ABDS (PRINTBALES) ################# */

#compte-rendu-content {
  font-size: 15px;
  line-height: 1.3;
}

.printable-table {
  width: 100%;
}
.printable-table,
.printable-table th,
.printable-table td {
  padding: 7px 10px;
  border: 1px solid #adadad;
  border-collapse: collapse;
  text-align: center;
}

.printable-table :is(td, th):empty::after {
  /* NOTE: this is to fix padding issues for empty cells*/
  content: "\00a0";
}

.simple-title {
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  margin: 1rem auto;
}

.border-title {
  font-size: 1.25rem;
  padding: 0.5rem 2rem;
  border: 2px solid black;
  margin: 1rem auto;
}

.underline-title {
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-decoration: underline;
  margin: 1rem auto;
}
.bg-title {
  width: 100%;
  color: white;
  background-color: #a7a6a6;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
}

.bordered-section {
  margin: 1rem 0;
  padding: 0.75rem;
  border: 1.5px solid black;
  line-height: 1.3;
}
.bordered-section-round {
  margin: 1rem 0;
  padding: 0.75rem;
  border: 1px solid black;
  border-radius: 1rem;
  font-size: 1.1rem;
  line-height: 1.3;
}

.st {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1.5rem 0;
}

#services-colors {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  padding: 15px;
}

.ecgForm .usageCount {
  display: none;
}

/*
//////////////////////////////////////////////////
////////////////   Waiting screen formats  ////////////////////////
//////////////////////////////////////////////////
*/

.waitingScreenFormatPreview {
  height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50% 50%;
  gap: 20px;
}

.waitingScreenFormatPreview .quadrant {
  background: RGBA(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* ########################## Display screen 1 ########################## */

.displayFormat1Type .quadrant1 {
}
.displayFormat1Type .quadrant4 {
}

/* ########################## Display screen 1 ########################## */

.displayFormat2Type .quadrant1 {
  grid-column: span 2;
  grid-row: span 2;
}
.displayFormat2Type .quadrant2,
.displayFormat2Type .quadrant3,
.displayFormat2Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 1 ########################## */

.displayFormat3Type .quadrant1 {
  grid-column: span 2;
}
.displayFormat3Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 2 ########################## */

.displayFormat4Type .quadrant3 {
  grid-column: span 2;
}
.displayFormat4Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 5 ########################## */

.displayFormat5Type .quadrant1,
.displayFormat5Type .quadrant2 {
  grid-column: span 2;
}
.displayFormat5Type .quadrant3,
.displayFormat5Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 6 ########################## */
.displayFormat6Type {
  grid-template-rows: 100%;
}
.displayFormat6Type .quadrant3,
.displayFormat6Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 7 ########################## */

.displayFormat7Type .quadrant1 {
  grid-row: span 2;
}
.displayFormat7Type .quadrant4 {
  display: none;
}

/* ########################## Display screen 8 ########################## */
.displayFormat8Type .quadrant2 {
  grid-row: span 2;
}
.displayFormat8Type .quadrant4 {
  display: none;
}

.background{
  background: var(--bluegray-bg);

}